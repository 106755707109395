import * as $ from 'jquery';

export class Helpers {
  static loadStyles(tag, src) {
    if (Array.isArray(src)) {
      // tslint:disable-next-line:only-arrow-functions
      $.each(src, function (k, s) {
        $(tag).append($('<link/>').attr('href', s).attr('rel', 'stylesheet').attr('type', 'text/css'));
      });
    } else {
      $(tag).append($('<link/>').attr('href', src).attr('rel', 'stylesheet').attr('type', 'text/css'));
    }
  }

  static setLoading(enable) {
    if (enable) {
      $('body').addClass('m-page--loading-non-block');
      $('.body-blockui').addClass('active');
    } else {
      $('body').removeClass('m-page--loading-non-block');
      $('.body-blockui').removeClass('active');
    }
  }

  static bodyClass(strClass) {
    $('body').attr('class', strClass);
  }

  static addClass(strClass, element) {
    $('#' + element).attr('class', strClass);
  }
}
