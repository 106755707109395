import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Import Containers
import { DefaultLayoutComponent } from './containers';
import { CampaignComponent } from './modules/campaign/component/campaign.component';
import { HomeComponent } from './modules/home/home.component';

export const routes: Routes = [

  { path: '404', redirectTo: '/error/404', pathMatch: 'full' },
  { path: '405', redirectTo: '', pathMatch: 'full' },
  { path: '500', redirectTo: '/error/500', pathMatch: 'full' },
  { path: 'dealership', redirectTo: '/dealership/list', pathMatch: 'full' },
  { path: 'login', loadChildren: './modules/login/login.module#LoginModule' },
  { path: 'logonsso', loadChildren: './modules/logonsso/logonsso.module#LogonSSOModule' },
  { path: 'error', loadChildren: './modules/error/error.module#ErrorModule' },
  { path: 'filemanager', loadChildren: './modules/filemanager/filemanager.module#FilemanagerModule' },

  {
    path: '', component: DefaultLayoutComponent, data: { title: 'Home page' },
    children: [
      { path: '', component: HomeComponent, data: { title: 'Home page' } },
      { path: 'Posting', loadChildren: './modules/posting/posting.module#PostingModule' },
      { path: 'articles', loadChildren: './modules/articles/articles.module#ArticlesModule' },
      { path: 'postinguser', loadChildren: './modules/postinguser/postinguser.module#PostinguserModule' },
      { path: 'System', loadChildren: './modules/system/system.module#SystemModule' },
      { path: 'CarProperty', loadChildren: './modules/carproperty/carproperty.module#CarPropertyModule' },
      { path: 'articlehighlight', loadChildren: './modules/article-highlight/article-highlight.module#ArticleHighlightModule' },
      { path: 'specifics', loadChildren: './modules/specifics/specifics.module#SpecificsModule' },
      { path: 'presspr', loadChildren: './modules/presspr/presspr.module#PressPRModule' },
      { path: 'dealership', loadChildren: './modules/dealership/dealership.module#DealershipModule' },
      { path: 'Users', loadChildren: './modules/users/users.module#UsersModule' },
      { path: 'lead', loadChildren: './modules/lead/lead.module#LeadModule' },
      { path: 'bannerads', loadChildren: './modules/bannerads/bannerads.module#BanneradsModule' },
      { path: 'bannerbox', loadChildren: './modules/bannerbox/bannerbox.module#BannerboxModule' },
      { path: 'tags', loadChildren: './modules/tags/tags.module#TagsModule' },
      { path: 'color', loadChildren: './modules/color/color.module#ColorModule' },
      { path: 'carintro', loadChildren: './modules/carintro/carintro.module#CarintroModule' },
      { path: 'cities', loadChildren: './modules/cities/cities.module#CitiesModule' },
      { path: 'menu', loadChildren: './modules/menu/menu.module#MenuModule' },
      { path: 'brand', loadChildren: './modules/brand/brand.module#BrandModule' },
      { path: 'boxlink', loadChildren: './modules/boxlink/boxlink.module#BoxlinkModule' },
      { path: 'backlink', loadChildren: './modules/backlink/backlink.module#BackLinkModule' },
      { path: 'vote', loadChildren: './modules/vote/vote.module#VoteModule' },
      { path: 'boxcontent', loadChildren: './modules/boxcontents/boxcontents.module#BoxContentModule' },
      { path: 'anchortext', loadChildren: './modules/anchortext/anchortext.module#AnchorTextModule' },
      { path: 'xmlfacebook', loadChildren: './modules/xmlfacebook/xmlfacebook.module#XmlfacebookModule' },
      { path: 'funfact', loadChildren: './modules/funfact/funfact.module#FunfactModule' },
      { path: 'author', loadChildren: './modules/author/author.module#AuthorModule' },
      { path: 'carguide', loadChildren: './modules/carguide/carguide.module#CarGuideModule' },
      { path: 'carguidespec', loadChildren: './modules/carguidespec/carguidespec.module#CarGuideSpecModule' },
      { path: 'DealerPromos', loadChildren: './modules/dealerpromos/dealerpromos.module#DealerPromosModule' },
      { path: 'metadata', loadChildren: './modules/metadata/metadat.module#MetadataModule' },
      { path: 'bannerconfig', loadChildren: './modules/banneradsconfig/banneradsconfig.module#BanneradsConfigModule' },
      { path: 'campaign', loadChildren: './modules/campaign/campaign.module#CampaignModule' },
      { path: 'boxtexttemplate', loadChildren: './modules/boxtexttemplate/boxtexttemplate.module#BoxtextTemplateModule' },
      { path: 'configlink', loadChildren: './modules/configlinktemplate/configlinktemplate.module#ConfigLinkTemplateModule' },
      { path: 'salon', loadChildren: './modules/salon/salon.module#SalonModule' },
      { path: 'clearcache', loadChildren: './modules/clearcache/clearcache.module#ClearCacheModule' },

    ]
  },
  { path: '**', redirectTo: 'error/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
