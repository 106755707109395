export class RolesEnum {
  public static readonly Admin = 1;
  public static readonly PostingManagement = 2;
  public static readonly PostingByCusManagement = 3;
  public static readonly PostingStatistic = 4;
  public static readonly ArticleManagement = 5;
  public static readonly ArticleGroupManagement = 6;
  public static readonly ArticleCommentManagement = 7;
  public static readonly ArticleStatistic = 8;
  public static readonly MemberManagement = 9;
  public static readonly UserCrawlerManagement = 10;
  public static readonly SalesManagement = 11;
  public static readonly BannerBoxManagement = 12;
  public static readonly LinkBoxManagement = 13;
  public static readonly SEOBoxManagement = 14;
  public static readonly TagManagement = 15;
  public static readonly DealershipsManagement = 16;
  public static readonly FacebookAds = 17;
  public static readonly AddProduct = 18;
  public static readonly PostingStaffManagement = 19;
  public static readonly NewsPaperManagement = 20;//Quản lý Newspaper
  public static readonly PropertyStructureManagement = 21;//Quản lý Property Structure Management
  public static readonly BackLinkManagement = 22;//Quản lý BackLink
  public static readonly DealerPromosManagement = 23;//Quản lý DealerPromo
  public static readonly BoxContentManagement = 24;//Quản lý nội dung các box: spolight..
  public static readonly DealerCommentManagement = 25;//Quản lý comment of dealer
  public static readonly VoteManagement = 26;
  public static readonly BrandManagement = 27;//Quản lý brand, model
  public static readonly BoxtextManagement = 28; // Quan ly boxtext tren listing
  public static readonly SalonManagement = 29;
}

export class RolesGroupEnum {
  public static readonly XMLFaceBookCacheMinute = 5;
  public static readonly LeaderBTVArticleGroupRoleId = 6;
  public static readonly CTVArticleGroupRoleId = 7;
  public static readonly CTVPostingGroupRoleId = 9;
  public static readonly PostingStaffGroupRoleId = 14;
}
