import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

interface Configuration {
  PRODUCTION: boolean,
  APP_TITLE: string,
  APP_CLIENT_URL: string,
  APP_API_URL: string,
  SERVER_LINK_IMAGE: string,
  LOGIN_SSO: true,
  LOGIN_URL: string,
  CMS_EXTERNAL_API_URL: string,
  YOUTUBE_IMAGE_URL: string,
  YOUTUBE_IMAGE_TYPE: string,
  YOUTUBE_API_KEY: string,
  VERSION: string,
}

@Injectable({ providedIn: 'root' })
export class ConfigAssetLoaderService {

  private CONFIG_URL = 'assets/config/config.json';
  public configData: Configuration;

  constructor(private http: HttpClient) {
  }

  private setConfigUrl(): void {
    const hostname = window && window.location && window.location.hostname;
    if (/^.*localhost.*/.test(hostname)) {
      this.CONFIG_URL = "assets/config/config.json";
    } else if (/^.*dvg-lc.*/.test(hostname) || /^.*172.16.0.*/.test(hostname)) {
      this.CONFIG_URL = "assets/config/configStaging.json";
    } else if (/^.*beta.admin.philkotse.*/.test(hostname)) {
      this.CONFIG_URL = "assets/config/configUat.json";
    } else if (/^.*admin.philkotse.*/.test(hostname)) {
      this.CONFIG_URL = "assets/config/configProduction.json";
    }
  }

  public initConfigurations(): any {
    if (!environment.INIT) {
      this.setConfigUrl();
      return this.http
        .get<Configuration>(this.CONFIG_URL)
        .subscribe(result => {
          environment.PRODUCTION = result["PRODUCTION"];
          environment.APP_TITLE = result["APP_TITLE"];
          environment.APP_CLIENT_URL = result["APP_CLIENT_URL"];
          environment.APP_API_URL = result["APP_API_URL"];
          environment.API_XMLFACEBOOK_URL = result["API_XMLFACEBOOK_URL"];
          environment.SERVER_LINK_IMAGE = result["SERVER_LINK_IMAGE"];
          environment.LOGIN_SSO = result["LOGIN_SSO"];
          environment.LOGIN_URL = result["LOGIN_URL"];
          environment.CMS_EXTERNAL_API_URL = result["CMS_EXTERNAL_API_URL"];
          environment.YOUTUBE_IMAGE_URL = result["YOUTUBE_IMAGE_URL"];
          environment.YOUTUBE_IMAGE_TYPE = result["YOUTUBE_IMAGE_TYPE"];
          environment.YOUTUBE_API_KEY = result["YOUTUBE_API_KEY"];
          environment.VERSION = result["VERSION"];
          environment.PHILKOTSE_WEB = result["PHILKOTSE_WEB"];
          environment.CMS_NEWCORE_URL = result["CMS_NEWCORE_URL"];
          environment.INIT = true;
        });
    }
  }

  get config(): Configuration {
    return this.configData;
  }
}
