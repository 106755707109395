// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  PRODUCTION: false,
  INIT: false,
  APP_TITLE: '',
  APP_CLIENT_URL: '',
  APP_API_URL: 'https://beta-api.admin.philkotse.nextgen-global.com',
  API_XMLFACEBOOK_URL: 'http://philkotse.local/xmlfacebook/genxml?accessToken=AutoPhi|replacethistexttoapassword',
  SERVER_LINK_IMAGE: 'https://img.philkotse.com/',
  LOGIN_SSO: false,
  LOGIN_URL: 'https://sso.daivietgroup.net/account/logincallback',
  CMS_EXTERNAL_API_URL: '',
  YOUTUBE_IMAGE_URL: '',
  YOUTUBE_IMAGE_TYPE: '',
  YOUTUBE_API_KEY: '',
  VERSION: "",
  PHILKOTSE_WEB: "",
  CMS_NEWCORE_URL: "https://new-admin-philkotse.nextgen-global.com",
  salonApi: {
    href: 'https://cms-awg.dvg-lc.com',
    gateWaySurfix: 'salon',
    version: 'v1'
  },
  commonApi: {
    href: 'https://cms-awg.dvg-lc.com',
    gateWaySurfix: 'common',
    version: 'v1'
  }
};
// export const environment = {
//   PRODUCTION: false,
//   APP_TITLE: "AP CMS Philippines (Development)",
//   APP_CLIENT_URL: "http://localhost:4200",
//   APP_API_URL: "http://cms.api.phil.vn",
//   SERVER_LINK_IMAGE: "https://img.philkotse.com/",
//   LOGIN_SSO: false,
//   LOGIN_URL: "https://sso.daivietgroup.net/account/logincallback",
//   CMS_EXTERNAL_API_URL: "http://localhost:49953/api/xmlfacebook/GenXML?accessToken=AutoPhi|replacethistexttoapassword",
//   YOUTUBE_IMAGE_URL: "https://img.youtube.com/vi/",
//   YOUTUBE_IMAGE_TYPE: "/maxresdefault.jpg",
//   YOUTUBE_API_KEY: "'AIzaSyDZ1A_hOlx8wqP9jwK7nSUctEkAmzeZt4o",
//   VERSION: "23032020",
//   PHILKOTSE_WEB: "http://philkotse.local"
// };
