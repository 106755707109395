import * as $ from 'jquery';
import { Component, OnDestroy, Inject, ViewEncapsulation, OnInit, AfterViewInit, AfterViewChecked } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems, NavData } from '../../_nav';
import { AuthenService } from '../../services/authen.service';
import { RolesEnum, RolesGroupEnum } from '../../utils/enums/roles.enum';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';
import { Const } from '../../utils/consts/const';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styles: ['.sidebar .nav-link {font-size: 13px;}'],
  encapsulation: ViewEncapsulation.None,
})
export class DefaultLayoutComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  private allowFixMenu = true;
  public IsShowSystemMenu = false;
  public UserName = '';
  public UserId: number;
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  public currentYear = new Date().getFullYear();

  constructor(private authenService: AuthenService,
    private httpService: HttpService,
    @Inject(DOCUMENT) _document?: any) {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });

    if (!AuthenService.IsAuthenticated) {
      this.httpService.doRedirectLogin();
      return;
    }

    this.UserName = AuthenService.getDisplayName();
    this.UserId = AuthenService.getUserId();
  }

  private leftMenuPermission(roleArr, groupArr) {
    // const removeParent = [];
    // let indexParent = 0;
    for (let i = 0; i < this.navItems.length; i++) {
      const parent = this.navItems[i];
      if (parent.children != null && parent.children.length > 0) {
        const removeChild = [];
        for (let j = 0; j < parent.children.length; j++) {
          const children = parent.children[j];
          let isPermission1 = true;
          let isPermission2 = true;
          let isPermission3 = true;
          if (children.roles != null) {
            isPermission1 = children.roles.some(x => roleArr.includes(x + ''));
          }
          if (children.group != null) {
            isPermission2 = children.group.some(x => groupArr.includes(x + ''));
          }
          if (children.notgroup != null) {
            isPermission3 = !children.notgroup.some(x => groupArr.includes(x + ''));
          }
          if (!isPermission1 || !isPermission2 || !isPermission3) {
            children.attributes = { 'class': 'd-none' };
          } else {
            children.attributes = null;
          }
          if (children.name === 'List member' && children.group.includes(RolesGroupEnum.PostingStaffGroupRoleId)) {
            children.url = '/postinguser/getlistmember/' + this.UserId;
          }
        }

        if (parent.children.filter(x => x.attributes != null).length === parent.children.length) {
          parent.attributes = { 'class': 'd-none' };
        } else {
          parent.attributes = null;
        }
      }
    }
  }

  public doLogout() {
    this.authenService.doLogout().subscribe();
  }

  ngOnInit(): void {
    // Roles
    let roles = AuthenService.getRoles();
    if (roles.length > 0) {
      const roleArr = roles.split(',');
      roles = ',' + roles + ',';
      this.IsShowSystemMenu = roles.includes(',' + RolesEnum.Admin + ',');

      // Group
      const groups = AuthenService.getGroups();
      const groupArr = groups.split(',');

      // Phân quyền menu
      this.leftMenuPermission(roleArr, groupArr);

    } else {
      // Phân quyền menu
      this.leftMenuPermission('', '');
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.allowFixMenu = false; }, 1000);
  }

  ngAfterViewChecked(): void {
    if (this.allowFixMenu) {
      $('app-sidebar-nav-dropdown').each(function () {
        $(this).removeClass('open');
        $(this).find('app-sidebar-nav-items app-sidebar-nav-link a').each(function () {
          if ($(this).hasClass('active')) {
            const parent = $(this).closest('app-sidebar-nav-dropdown');
            if (!parent.hasClass('open')) {
              parent.addClass('open');
              parent.addClass('text-info');
            }
          }
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }


}
