import { environment } from '../../../environments/environment';
import { NotifierOptions } from 'angular-notifier';

export class Const {
  public static readonly APP_TITLE: "AP CMS Philippines";
  public static readonly MinDate: Date = new Date('0001-01-01T00:00:00');
  public static readonly MY_DATETIMEPICKER_FORMAT = {
    parseInput: 'DD/MM/YYYY HH:mm',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD',
    timePickerInput: 'HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: ' YYYY MMMM',
  };
  public static readonly CKPostingConfig = {
    enterMode: '2',
    height: 200,
    toolbar: [
      {
        name: 'insert',
        items: [
          'Bold',
          'Italic',
          'Underline',
          'Maximize',
          'Source',
          'NumberedList',
          'BulletedList',
          'Outdent',
          'Indent',
          'Link',
          'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock',
          'Preview'
        ]
      }
    ]
  };
  public static readonly CKArticleConfig = {
    skin: 'moono-lisa,/assets/ckeditor/skins/moono-lisa/',
    enterMode: '1',
    height: 400,
    allowedContent: true,
    toolbarCanCollapse: true,
    removePlugins: 'magicline',
    extraPlugins:
      'youtube,dvs.image,dvs.slidercompare,eqneditor,dvs.tabletemplate,dvs.oembed,dvs.linkinternal,dvs.buttonlink,dvs.duration,dvs.vote,dvs.relatedlink,dvs.getlead,dvs.promoappend,dvs.boxprosandcons,dvs.boxfaq',
    youtube_related: false,
    toolbar: [
      {
        name: 'document',
        items: [
          'Source',
          '-',
          'Save',
          'NewPage',
          'Preview',
          'Print',
          '-',
          'Templates'
        ]
      },
      {
        name: 'clipboard',
        items: [
          'Cut',
          'Copy',
          'Paste',
          'PasteText',
          'PasteFromWord',
          '-',
          'RemoveFormat',
          '-',
          'Undo',
          'Redo'
        ]
      },
      // {
      //   name: 'forms',
      //   items: [
      //     'Form',
      //     'Checkbox',
      //     'Radio',
      //     'TextField',
      //     'Textarea',
      //     'Select',
      //     'Button',
      //     'ImageButton',
      //     'HiddenField'
      //   ]
      // },
      { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
      '/',
      {
        name: 'editing',
        items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt']
      },
      {
        name: 'basicstyles',
        items: [
          'Bold',
          'Italic',
          'Underline',
          'Strike',
          'Subscript',
          'Superscript'
        ]
      },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      {
        name: 'paragraph',
        items: [
          'NumberedList',
          'BulletedList',
          '-',
          'Outdent',
          'Indent',
          '-',
          'Blockquote',
          'CreateDiv',
          '-',
          'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock',
          '-',
          'BidiLtr',
          'BidiRtl'
        ]
      },
      { name: 'links', items: ['Link', 'Unlink', 'Anchor', 'dvs.linkinternal', 'dvs.buttonlink', 'dvs.duration'] },
      '/',
      {
        name: 'insert',
        items: [
          'dvs.getlead',
          'dvs.promoappend',
          'dvs.boxprosandcons',
          'dvs.boxfaq',
          'dvs.relatedlink',
          'dvs.vote',
          'Image',
          'Flash',
          'dvs.tabletemplate',
          'HorizontalRule',
          'Smiley',
          'SpecialChar',
          'PageBreak',
          'Iframe',
          'Youtube',
          'Templates',
          'EqnEditor',
          'dvs.image',
          'dvs.slidercompare',
          'dvs.oembed'
        ]
      },
      { name: 'styles', items: ['Styles', 'Format'] }
    ]
  };

  public static readonly CKShortConfig = {
    skin: 'moono-lisa,/assets/ckeditor/skins/moono-lisa/',
    enterMode: '1',
    allowedContent: true,
    toolbarCanCollapse: true,
    removePlugins: 'magicline',
    youtube_related: false,
    toolbar: [
      {
        name: 'basicstyles',
        items: [
          'Bold',
          'Italic',
          'Underline',
          'Strike',
          'Subscript',
          'Superscript'
        ]
      },
      {
        name: 'paragraph',
        items: [
          'NumberedList',
          'BulletedList',
          '-',
          'Outdent',
          'Indent',
          '-',
          'Blockquote',
          'CreateDiv',
          '-',
          'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock',
          '-',
          'BidiLtr',
          'BidiRtl'
        ]
      },
      { name: 'links', items: ['Link', 'Unlink', 'Anchor', 'dvs.linkinternal', 'dvs.buttonlink', 'dvs.duration'] },
      '/',
      { name: 'styles', items: ['Styles', 'Format'] }
    ]
  };

  public static readonly CKFunfactConfig = {
    skin: 'moono-lisa,/assets/ckeditor/skins/moono-lisa/',
    enterMode: '1',
    height: 400,
    allowedContent: true,
    extraPlugins:
      'youtube,dvs.image',
    toolbar: [
      {
        name: 'document',
        items: [
          'Source',
          '-',
          'Preview',
          'Youtube',
          'dvs.image',
          'Cut',
          'Copy',
          'Paste',
          'PasteText',
          'PasteFromWord',
          '-',
          'RemoveFormat',
          '-',
          'Undo',
          'Redo',
          'Maximize',
          'Find', 'Replace', '-', 'SelectAll'
        ]
      },
      {
        name: 'basicstyles',
        items: [
          'Bold',
          'Italic',
          'Underline',
          'Strike',
          'Subscript',
          'Superscript'
        ]
      },
      {
        name: 'paragraph',
        items: [
          'NumberedList',
          'BulletedList',
          '-',
          'Outdent',
          'Indent',
          '-',
          'Blockquote',
          'CreateDiv',
          '-',
          'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock',
          '-',
          'BidiLtr',
          'BidiRtl',
          'Styles', 'Format', 'Link'
        ]
      }
    ]
  };

  public static readonly customNotifierOptions: NotifierOptions = {
    position: {
      horizontal: {
        position: 'right',
        distance: 12
      },
      vertical: {
        position: 'top',
        gap: 10
      }
    },
    behaviour: {
      autoHide: 3000,
      onClick: 'hide',
      onMouseover: 'pauseAutoHide',
      showDismissButton: true,
      stacking: 4
    },
    animations: {
      enabled: true,
      show: {
        preset: 'slide',
        speed: 300,
        easing: 'ease'
      },
      hide: {
        preset: 'fade',
        speed: 300,
        easing: 'ease',
        offset: 50
      },
      shift: {
        speed: 300,
        easing: 'ease'
      },
      overlap: 150
    }
  };

  public static readonly FORMATS_DATE = {
    parseInput: 'DD/MM/YYYY HH:mm',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: 'LT',
    monthYearLabel: 'M YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'M YYYY'
  };
}

