import { environment } from './../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
declare var CKEDITOR: any;
@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() {

    // xử lý khi đăng nhập sso
    const url = new URL(window.location.href);
    const ssoCallbackObj = url.searchParams.get('data');
    if (ssoCallbackObj != null) {
      this.router.navigate(['/logonsso'], {
        queryParams: { data: ssoCallbackObj }
      });
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    const version = environment.VERSION;
    this.addJsToElement("assets/apiconfigs.js?v=" + version);
    if (location.href.includes("articles/detai") || location.href.includes("carguide/edit")
      || location.href.includes("carguide/add")
      || location.href.includes("reviewdetail") || location.href.includes("videodetail")
      || location.href.includes("clone")
      || location.href.includes("author/detai") || location.href.includes("funfact")
      || location.href.includes("Posting/Edit")
      || location.href.includes("carintro")
      || location.href.includes("dealership/edit")
      || location.href.includes("Posting/PostingBoxtext")
      || location.href.includes("salon/detail")
    ) {
      //this.addJsToElement("https://cdn.ckeditor.com/4.5.11/full/ckeditor.js");
      this.addJsToElement("https://cdn.ckeditor.com/4.16.1/full-all/ckeditor.js");
      this.addJsToElement("assets/ckeditor-init.js?v=" + version);
    }

    if (location.href.includes("dealership/edit")) {
      this.addJsToElement("https://maps.googleapis.com/maps/api/js?key=AIzaSyBcvt68iGFu-9Zkw43sXzn8vVmkYHpLVek", true, true);
    }
  }

  addJsToElement(src: string, async = false, defer = false): HTMLScriptElement {
    const node = document.createElement('script');
    node.type = 'text/javascript';
    node.src = src;
    node.async = async;
    node.defer = defer;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
    return node;
  }
}
