import { BaseComponent } from './modules/share/components/base/base.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';

import { DefaultLayoutComponent } from './containers';
import { TreeModule } from 'angular-tree-component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
//import { ChartsModule } from 'ng2-charts/ng2-charts';
import { ChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CKEditorModule } from 'ng2-ckeditor';
import { HomeComponent } from './modules/home/home.component';
import { NgxLoadingModule } from 'ngx-loading';
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CrystalGalleryModule } from 'ngx-crystal-gallery';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
// Collapse module
import { CollapseModule } from 'ngx-bootstrap/collapse';
// Tooltip module
// import { ArticlesComponent } from './views/articles/articles.component';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { DirectiveModule } from './utils/directive/directive.module';
import { ConfigAssetLoaderService } from './services/config-asset-loader.service';
import { Const } from './utils/consts/const';
import { AgmCoreModule } from '@agm/core';
//import { Rating, RatingModule } from 'ngx-rating';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    // tslint:disable-next-line: deprecation
    HttpModule,
    FormsModule,
    CKEditorModule,
    TreeModule.forRoot(),
    HttpClientModule,
    NotifierModule.withConfig(Const.customNotifierOptions),
    NgxLoadingModule.forRoot({}),
    NgSelectModule,
    NgxPaginationModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    OwlDateTimeModule, // ng-datetime-picker
    OwlNativeDateTimeModule, // ng-datetime-picker
    MatStepperModule,
    CarouselModule.forRoot(),
    CrystalGalleryModule,
    DirectiveModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBcvt68iGFu-9Zkw43sXzn8vVmkYHpLVek',
      libraries: ['places']
    }),
    //RatingModule, Rating
  ],
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    HomeComponent,
    BaseComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (envConfigService: ConfigAssetLoaderService) => () => envConfigService.initConfigurations(),
      deps: [ConfigAssetLoaderService],
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
