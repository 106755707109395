import { RolesEnum, RolesGroupEnum } from './utils/enums/roles.enum';
import { environment } from '../environments/environment';

interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
  roles?: number[];
  group?: number[];
  notgroup?: number[];
}
export const navItems: NavData[] = [
  {
    name: 'Postings management',
    icon: 'fa fa-balance-scale text-primary',
    class: 'text-primary',
    children: [
      {
        name: 'Crawler posting',
        url: '/Posting/Posting',
        icon: 'fa fa-cloud-download',
        roles: [RolesEnum.Admin, RolesEnum.PostingManagement]
      },
      {
        name: 'Customer posting',
        url: '/Posting/PostingByCus/0',
        icon: 'fa fa-vcard-o',
        roles: [RolesEnum.Admin, RolesEnum.PostingByCusManagement],
        notgroup: [RolesGroupEnum.PostingStaffGroupRoleId]
      },
      {
        name: 'Crawler promos',
        url: '/DealerPromos/Crawler',
        icon: 'fa fa-vcard-o',
        roles: [RolesEnum.Admin, RolesEnum.DealerPromosManagement],
        notgroup: [RolesGroupEnum.PostingStaffGroupRoleId]
      },
      {
        name: 'Philkotse promos',
        url: '/DealerPromos/User',
        icon: 'fa fa-vcard-o',
        roles: [RolesEnum.Admin, RolesEnum.DealerPromosManagement],
        notgroup: [RolesGroupEnum.PostingStaffGroupRoleId]
      },
      {
        name: 'Featured promos',
        url: '/DealerPromos/position/1',
        icon: 'fa fa-star-o',
        roles: [RolesEnum.Admin, RolesEnum.DealerPromosManagement],
        notgroup: [RolesGroupEnum.PostingStaffGroupRoleId]
      },
      {
        name: 'Campaign',
        url: '/campaign',
        icon: 'fa fa-bullhorn',
        roles: [RolesEnum.Admin, RolesEnum.PostingManagement]
      },
      {
        name: 'Statistic Posting Crawler ',
        url: '/Posting/PostingStatistic',
        icon: 'fa fa-area-chart',
        roles: [RolesEnum.Admin, RolesEnum.PostingStatistic],
        notgroup: [RolesGroupEnum.CTVPostingGroupRoleId, RolesGroupEnum.CTVArticleGroupRoleId]
      },
      {
        name: 'P.Customer statistic',
        url: '/Posting/PostingByCusStatistic',
        icon: 'fa fa-bar-chart',
        roles: [RolesEnum.Admin, RolesEnum.PostingStatistic],
        notgroup: [RolesGroupEnum.CTVPostingGroupRoleId, RolesGroupEnum.CTVArticleGroupRoleId]
      },
      {
        name: 'P.Published statistic',
        url: '/Posting/PostingPublishedStatistic',
        icon: 'fa fa-line-chart',
        roles: [RolesEnum.Admin, RolesEnum.PostingStatistic]
      },
    ]
  },
  {
    name: 'Articles management',
    icon: 'fa fa-newspaper-o text-primary',
    class: 'text-primary',
    children: [
      {
        name: 'Car Guide',
        url: '/carguide',
        icon: 'fa fa-building',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'Specification Config',
        url: '/carguidespec',
        icon: 'fa fa-building',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'News',
        url: '/articles/1/0',
        icon: 'fa fa-file-text-o',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'Car Compare',
        url: '/articles/3/0',
        icon: 'fa fa-random',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'Tips & Advice',
        url: '/articles/5/0',
        icon: 'fa fa-file-text',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'Car Price',
        url: '/articles/4/0',
        icon: 'fa fa-money',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'Review',
        url: '/articles/review/2/0',
        icon: 'fa fa-car',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'Promos',
        url: '/articles/6/0',
        icon: 'fa fa-television',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'Video',
        url: '/articles/video',
        icon: 'fa fa-file-video-o',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'Brands',
        url: '/articles/7/0',
        icon: 'fa fa-building-o',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'Models',
        url: '/articles/8/0',
        icon: 'fa fa-building',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'Magazine',
        url: '/articles/13/0',
        icon: 'fa fa-file-powerpoint-o',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'List-ticles',
        url: '/articles/14/0',
        icon: 'fa fa-list-alt',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'Partner',
        url: '/articles/15/0',
        icon: 'fa fa-handshake-o',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'Author',
        url: '/author',
        icon: 'fa fa-building',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'Draft articles',
        url: '/articles/listdraft',
        icon: 'fa fa-pencil-square-o',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement]
      },
      {
        name: 'Featured articles/topics',
        url: '/articles/newsgroups',
        icon: 'fa fa-diamond',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement],
        notgroup: [RolesGroupEnum.CTVArticleGroupRoleId]
      },
      {
        name: 'Box Manager',
        url: '/articles/boxmanager',
        icon: 'fa fa-diamond',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement],
        notgroup: [RolesGroupEnum.CTVArticleGroupRoleId]
      },
      {
        name: 'User Review - Comment',
        url: '/articles/articlecomment',
        icon: 'fa fa-commenting-o',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement],
        notgroup: [RolesGroupEnum.CTVArticleGroupRoleId]
      },
      {
        name: 'Articles statistic',
        url: '/articles/statistic',
        icon: 'fa fa-bar-chart',
        roles: [RolesEnum.Admin, RolesEnum.ArticleManagement],
        notgroup: [RolesGroupEnum.CTVArticleGroupRoleId]
      },
    ]
  },
  {
    name: 'Users management',
    icon: 'fa fa-users text-primary',
    class: 'text-primary',
    children: [
      {
        name: 'Member',
        url: '/Users/Customers',
        icon: 'fa fa-user',
        roles: [RolesEnum.Admin, RolesEnum.MemberManagement]
      },
      {
        name: 'User crawler',
        url: '/Users/UserCrawler',
        icon: 'fa fa-user-secret',
        roles: [RolesEnum.Admin, RolesEnum.UserCrawlerManagement]
      },
    ]
  },
  {
    name: 'P.User management',
    icon: 'fa fa-user-secret text-primary',
    class: 'text-primary',
    children: [
      {
        name: 'Posting user',
        url: '/postinguser/getlist',
        icon: 'fa fa-user',
        roles: [RolesEnum.Admin, RolesEnum.PostingStaffManagement]
      },
      {
        name: 'List member',
        url: '/postinguser/getlistmember/',
        icon: 'fa fa-users',
        group: [RolesGroupEnum.PostingStaffGroupRoleId]
      },
      {
        name: 'Posting Added Statistic',
        url: '/postinguser/postingaddedstatistic',
        icon: 'fa fa-bar-chart',
        roles: [RolesEnum.Admin, RolesEnum.PostingStaffManagement]
      }
    ]
  },
  {
    name: 'Partner Management',
    icon: 'fa fa-id-card-o text-primary',
    class: 'text-primary',
    children: [
      {
        name: 'DealerShips',
        url: '/dealership/list',
        icon: 'fa fa-user',
        roles: [RolesEnum.Admin, RolesEnum.DealershipsManagement]
      },
      {
        name: 'DealerShips Featured',
        url: '/dealership/position/1',
        icon: 'fa fa-star-o',
        roles: [RolesEnum.Admin, RolesEnum.DealershipsManagement]
      },
      {
        name: 'Partner Account',
        url: '/dealership/account',
        icon: 'fa fa-user',
        roles: [RolesEnum.Admin, RolesEnum.DealershipsManagement]
      },
      {
        name: 'Partner Level',
        url: '/dealership/level',
        icon: 'fa fa-angle-double-up',
        roles: [RolesEnum.Admin, RolesEnum.DealershipsManagement]
      },
      {
        name: 'Feedbacks',
        url: '/dealership/feedback',
        icon: 'fa fa-rss',
        roles: [RolesEnum.Admin, RolesEnum.DealershipsManagement]
      },
      {
        name: 'DealerShips Review',
        url: '/dealership/dealercomment',
        icon: 'fa fa-star-o',
        roles: [RolesEnum.Admin, RolesEnum.DealershipsManagement]
      },
    ]
  },
  {
    name: 'Lead management',
    icon: 'fa fa-vcard text-primary',
    class: 'text-primary',
    children: [
      {
        name: 'Lead list',
        url: '/lead/leadlist',
        icon: 'fa fa-list-alt',
        roles: [RolesEnum.Admin, RolesEnum.DealershipsManagement]
      },
      {
        name: 'Lead statistic',
        url: '/lead/leadstatistic',
        icon: 'fa fa-pie-chart',
        roles: [RolesEnum.Admin, RolesEnum.DealershipsManagement]
      },
    ]
  },
  {
    name: 'Other management',
    url: '/SystemManagement/User',
    icon: 'fa fa-th text-primary',
    class: 'text-primary',
    children: [
      {
        name: 'Press PR',
        url: '/presspr',
        icon: 'fa fa-product-hunt',
        roles: [RolesEnum.Admin, RolesEnum.NewsPaperManagement]
      },
      {
        name: 'Bannerbox',
        url: '/bannerbox',
        icon: 'fa fa-th-large',
        roles: [RolesEnum.Admin, RolesEnum.BannerBoxManagement]
      },
      {
        name: 'Banner Ads',
        url: '/bannerads',
        icon: 'fa fa-picture-o',
        roles: [RolesEnum.Admin, RolesEnum.LinkBoxManagement]
      },
      {
        name: 'Banner Ads Config',
        url: '/bannerconfig',
        icon: 'fa fa-picture-o',
        roles: [RolesEnum.Admin, RolesEnum.LinkBoxManagement]
      },
      {
        name: 'Backlink',
        url: '/backlink',
        icon: 'fa fa-external-link',
        roles: [RolesEnum.Admin, RolesEnum.LinkBoxManagement]
      },
      {
        name: 'Boxlink',
        url: '/boxlink',
        icon: 'fa fa-external-link',
        roles: [RolesEnum.Admin, RolesEnum.LinkBoxManagement]
      },
      {
        name: 'Boxlink Spotlight',
        url: '/boxcontent',
        icon: 'fa fa-external-link',
        roles: [RolesEnum.Admin, RolesEnum.BoxContentManagement]
      },
      {
        name: 'Color',
        url: '/color',
        icon: 'fa fa-tags',
        roles: [RolesEnum.Admin, RolesEnum.TagManagement]
      },
      {
        name: 'Tag',
        url: '/tags',
        icon: 'fa fa-tags',
        roles: [RolesEnum.Admin, RolesEnum.TagManagement]
      },
      {
        name: 'Anchor Text',
        url: '/anchortext',
        icon: 'fa fa-anchor',
        roles: [RolesEnum.Admin, RolesEnum.LinkBoxManagement]
      },
      {
        name: 'Car Introduce',
        url: '/carintro',
        icon: 'fa fa-flag-o',
        roles: [RolesEnum.Admin, RolesEnum.LinkBoxManagement]
      },
      {
        name: 'Boxtext template',
        url: '/boxtexttemplate',
        icon: 'fa fa-file-text',
        roles: [RolesEnum.Admin, RolesEnum.PostingManagement]
      },
      {
        name: 'Config link',
        url: '/configlink',
        icon: 'fa fa-link',
        roles: [RolesEnum.Admin, RolesEnum.PostingManagement]
      },
      {
        name: 'Metadata',
        url: '/metadata',
        icon: 'fa fa-file-code-o',
        roles: [RolesEnum.Admin, RolesEnum.LinkBoxManagement]
      },
      {
        name: 'Fun fact',
        url: '/funfact',
        icon: 'fa fa-building-o',
        roles: [RolesEnum.Admin, RolesEnum.LinkBoxManagement]
      },
      {
        name: 'Menu',
        url: '/menu',
        icon: 'fa fa-info',
        roles: [RolesEnum.Admin, RolesEnum.LinkBoxManagement]
      },
      {
        name: 'Gen XML Facebook File',
        url: '/xmlfacebook',
        icon: 'fa fa-facebook',
        roles: [RolesEnum.Admin, RolesEnum.FacebookAds]
      },
      {
        name: 'Vote',
        url: '/vote',
        icon: 'fa fa-external-link',
        roles: [RolesEnum.Admin, RolesEnum.VoteManagement]
      },
      {
        name: 'Brand, Model, Variant',
        url: '/brand',
        icon: 'fa fa-building-o',
        roles: [RolesEnum.Admin, RolesEnum.BrandManagement]
      },
      {
        name: 'Clear cache',
        url: '/clearcache',
        icon: 'fa fa-trash',
        roles: [RolesEnum.Admin]
      },
    ]
  },
  // {
  //   name: 'Salon',
  //   icon: 'fa fa-handshake-o',
  //   class: 'text-primary',
  //   children: [
  //     {
  //       name: 'Salon list',
  //       url: '/salon/list',
  //       icon: 'fa fa-list-alt',
  //       roles: [RolesEnum.Admin, RolesEnum.DealershipsManagement]
  //     },
  //     {
  //       name: 'Salon box setting',
  //       url: '/salon/box-setting',
  //       icon: 'fa fa-pie-chart',
  //       roles: [RolesEnum.Admin, RolesEnum.DealershipsManagement]
  //     },
  //   ]
  // },
  // {
  //   name: 'New car & Promo',
  //   url: environment.CMS_NEWCORE_URL,
  //   icon: 'fa fa-external-link',
  //   class: 'text-primary',
  //   attributes: { target: '_blank' }
  // }
];
