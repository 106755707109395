import { Component, OnInit } from '@angular/core';
import { Pager } from '../../../../model/ResponseData';

@Component({
  selector: 'app-base',
  template: ''
})
export class BaseComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
  }

  getContentPaging(pager: Pager) {
    // Page 1/5,887 (Total records: 88,304)
    return 'Page ' + (pager.TotalItem > 0 ? this.formatNumber(pager.CurrentPage) : '0') +
      '/' + this.formatNumber(pager.TotalPage) + '  (Total records: ' + this.formatNumber(pager.TotalItem) + ')';
  }

  checkCompare(objA: any, objB: any) {
    if (objA === undefined || objA === null || objA === '') {
      return false;
    }

    if (objB === undefined || objB === null || objB === '') {
      return false;
    }

    if (objA.toString() === objB.toString()) {
      return true;
    }

    return false;
  }

  checkIsNullOrEmpty(obj: any, noZero = false) {
    if (obj === undefined || obj === null || obj === '' || obj.trim(' ') === '') {
      return true;
    }

    if (noZero && obj.toString() === '0') {
      return true;
    }

    return false;
  }

  checkIsNull(obj: any) {
    if (obj === undefined || obj === null) {
      return true;
    }
    return false;
  }

  checkListIsNotEmpty(obj: any[]) {
    if (obj !== undefined && obj != null && obj.length > 0) {
      return true;
    }
    return false;
  }

  checkNumber(obj: number) {
    if (obj !== undefined && obj !== null && obj > 0) {
      return true;
    }
    return false;
  }

  formatStringFromNumber(obj: string, allowZero = false) {
    if (allowZero && obj === '0') {
      return obj;
    }
    return this.formatNumber(this.getNumberFromString(obj));
  }

  formatNumber(obj: number) {
    if (obj === undefined || obj === null || obj.toString() === '0' || obj.toString() === 'NaN') {
      return '';
    }

    return obj.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  getString(obj: string, defaultReturn: string) {
    if (obj === null || obj === undefined || obj === '') {
      return defaultReturn;
    }

    return obj;
  }

  getNumberFromString(obj: string, defaultReturn = 0) {
    if (obj === undefined || obj === '') {
      return defaultReturn;
    }

    obj = obj.toString().replace(/[^0-9]+/gi, '');
    return parseInt(obj.toString(), 10);
  }

  collapsed(event: any): void { }

  expanded(event: any): void { }

  public countOccurr(keyword, controltocheck, checktype) {
    let result = 0;
    if (checktype === 'value') {
      const valueCheck = controltocheck;
      if (valueCheck === '') {
        return 0;
      }
      const partern = new RegExp('\\b' + keyword + '\\b', 'gi');
      result = (valueCheck.match(partern) || []).length;
    } else if (checktype === 'ckeditor') {
      const valueCheck = controltocheck.replace(/<[^>]*>/g, '');
      if (valueCheck === '') {
        return 0;
      }
      const partern = new RegExp('\\b' + keyword + '\\b', 'gi');
      result = (valueCheck.match(partern) || []).length;
    } else if (checktype === 'text') {
      const valueCheck = controltocheck;
      if (valueCheck === '') {
        return 0;
      }
      const partern = new RegExp('\\b' + keyword + '\\b', 'gi');
      result = (valueCheck.match(partern) || []).length;
    }
    return result;
  }

  public checkformatdate(value) {
    if (/^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/.test(value)) {
      return true;
    }
    return false;
  }

  public array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  public countOccurences(string, word) {
    var regExp = new RegExp(word, "gi");
    return (string.match(regExp) || []).length;
  }

  public GetYouTubeId(url) {
    var regex = /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|\&vi?=)([^#\&\?]*).*/;
    var match = url.match(regex);
    if (match) if (match.length >= 2) return match[2];
    return "";
  }
}
